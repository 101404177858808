<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
       
      <a-divider/>    
         <div id="CV" align="left">
                <p style="font-size:17px; margin-top: -10px; font-Weight:bold;">
                  Please find my CV <a href='/CV.pdf'>[here]</a>.
                </p>
         </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>