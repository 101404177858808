<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
      <div id="Review" align="left" >
          <p style="font-size:22px;margin-top:10px;">Review</p>
            <ul style="font-size:17px;margin-top:-15px;">
                <li>International Journal of Language Education</li>
            </ul>
      </div>
       
      <a-divider/>    
       <div id="Affiliations" align="left" >
            <p style="font-size:22px;margin-top:10px;">Professional affiliations</p>
            <ul style="font-size:17px;margin-top:-15px;">
                <li>Linguistics Society of America (2022 - present)</li>
                <li>Acoustical Society of America (2022 - 2023)</li>
                <li>International Speech Communication Association (2022 - 2023)</li>
                <li>Australasian Speech Science and Technology Association (2022 - 2023)</li>
            </ul>
            
        </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>