<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
      <div id="Addresses" align="left" >
          <p style="font-size:23px;margin-top:10px;">Addresses</p>
            <ul style="font-size:17px;margin-top:-15px;">
                <li>Email address: u6512077 (at) alumni.anu.edu.au</li>
                <li>Orcid: 0000-0003-0583-0831</li>
                <li>LinkedIn: <a href='https://www.linkedin.com/in/tianle-yang-4600a02b3/'>Tianle Yang</a></li>
                <li>ResearchGate address: <a href='https://www.researchgate.net/profile/Tianle-Yang-6'>Tianle Yang</a></li>
                <li>Google scholar address: Appear soon ~</li>
            </ul>
      </div>
       
      <a-divider/>    
         <div id="Contact1" align="left">
                <p style="font-size:18px; margin-top: -10px; font-Weight:bold;">
                    I am cooperating with researchers from Australia, USA and China. I'm also open to 
                    collaborations related to many topics and fields, addressing problems both from industry and academia.
                    Please don't hesitate to contact me if you are interested in my research.
                </p>
         </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>