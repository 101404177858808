<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
       
      <a-divider/>    
         <div id="Hainan1" align="left">
                <p style="font-size:23px; margin-top: -10px; font-Weight:bold;">
                  The variety of Hainan Min
                </p>

                <p style="font-size:16px; margin-top: -5px; font-Weight:bold;">
                  In Hainan, the local lingua franca is referred to as Hainanese. 
                  Numerous Wenchang (文昌) Hainanese descended from Jinshi and merchants 
                  from Putian according to many of their Jiapu (family genealogies). 
                  Many Hainanese surnames are found only in Cantonese people, such 
                  as 麥, 鄺, 譚, 岑 and 龐. They have ancestry from Cantonese immigrants 
                  who immigrated to the island in waves, especially during the Ming dynasty 
                  as indicated in historical records and their jiapus. Standard Chinese is 
                  also the lingua franca in the island province as in the rest of China. 
                  Most locals in Wenchang speak both their dialect and Mandarin. Unlike 
                  Cantonese in HK, the use of Hainanese is mainly confined to informal 
                  communication among locals.
                </p>

                <p style="font-size:16px; margin-top: 15px; font-Weight:bold;">
                  My investigation of Hainan Min will start with the Wenchang (文昌) dialect, 
                  which is considered the official form of Hainanese. In the previous 
                  description (Woon, 1979), the Wenchang dialect was reported to have six 
                  tones on isolated syllables. They are categorized in the table 1 below:
                </p>

                <div style="margin-left: 20px;">
                <img style="width: 450px;height: 200px;" src="../assets/Wenchang_tones.png" alt="">
                </div>

                <p style="font-size:16px; margin-top: 15px; font-Weight:bold;">
                  However, this description differs from the <a href='https://web.archive.org/web/20150518073528/http://www.hnszw.org.cn/data/news/2015/04/77216/'>local chronicles of Wenchang</a>, 
                  which says there are eight tones in this variety. Thus, my research aims to 
                  provide synchronic acoustic descriptions of tones in the Hainan Min varieties 
                  for further diachronic discussion of changes that happened to them. It would 
                  also be interesting to find out if Tone 4 and Tone 6 in table 1 are contrastive, 
                  as they are both described as high falling tones with glottal stops.

                </p>

                <p style="font-size:20px; margin-top: 15px; font-Weight:bold;">
                  References:
                </p>
                
                <p style="font-size:16px; margin-top: -15px; font-Weight:bold;">
                  Woon, Wee-Lee (1979a), "A synchronic phonology of Hainan dialect: Part I", Journal of Chinese Linguistics, 7 (1): 65–100, JSTOR 23753034
                </p>

                <p style="font-size:16px; margin-top: -15px; font-Weight:bold;">
                  Woon, Wee-Lee (1979b), "A synchronic phonology of Hainan dialect: Part II", Journal of Chinese Linguistics, 7 (2): 268–302, JSTOR 23752923
                </p>


                <p style="font-size:18px;margin-top:30px; font-Weight:bold;">
                  <router-link to='/005'>Back to previous page</router-link>
                </p>
         </div>

  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>