<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
      <div id="Welcome" align="left" >
          <p style="font-size:23px;margin-top:10px;">Welcome to my homepage</p>
          <p style="font-size:17px;margin-top:-10px;">
              This website is built for scientific communication. You can use it to find my latest publications, fieldwork data, 
              and updates on my current executing projects.

          </p>
      </div>
      <a-divider/>
      <div id="About" align="left" >
          <p style="font-size:22px;margin-top:10px;">About me</p>
          <p style="font-size:17px;margin-top:-10px;">
              I am a 2nd-year PhD student in Linguistics and pursuing an M.S. in 
              Computational Linguistics at the University at Buffalo, SUNY. 
              My research interests focus on the intersection of acoustic phonetics, 
              speech processing, and computational linguistics, with special emphasis 
              on speech analysis, deep learning techniques for speech processing, 
              and acoustic studies for language documentation.
            </p>

            <p style="font-size:17px;margin-top:-10px;">
              My journey in research began under the mentorship of 
              Dr. <a href='https://philjohnrose.net/'>Phil Rose</a>, whose guidance has been invaluable to my academic development.
              Before joining the PhD program at the University at Buffalo, I earned a Bachelor of Information Technology 
              from the Australian National University, where I received extensive training in computer science.
              I'm currently a member of both the UB <a href='https://ubwp.buffalo.edu/phonlab/'>Phonetics Lab</a> and 
              the UB <a href='https://www.acsu.buffalo.edu/~cxjacobs/index/CaLiCo_Lab.html'>Computational Linguistics</a> Lab. In addition, I serve as a research assistant 
              for Dr. <a href='https://www.acsu.buffalo.edu/~cxjacobs/'>Cassandra Jacobs</a> in the Computational Linguistics Lab, where I work on testing deep speech models 
              and comparing human speech production with machine-generated speech.
            </p>

      <a-divider/>    
       <div id="ResearchInterest" align="left" >
            <p style="font-size:22px;margin-top:10px;">Research Interests</p>
            <ul style="font-size:17px;margin-top:-15px;">
                <li>Acoustic phonetics;</li>
                <li>Speech processing;</li>
                <li>Computational linguistics;</li>
                <li>Language documentation and analysis;</li>
            </ul>
            
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>